import { useEffect } from "react";
import { ProductListdata } from "../assets/Productlist";
import { useNavigate } from "react-router-dom";
function ProductList() {
    const navigate = useNavigate()
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' or 'smooth' for smooth scrolling
          })
    },[])
    return (
        <>
            <section class="product-page-header">
                <div class="container">
                    <div class="welcome-hero-txt">
                        <h2>Where people meets <br /> Innovative Technology </h2>
                    </div>
                </div>
            </section>
            <section id="blog" class="blog">
                <div class="container">
                    <div class="section-header">
                        <h2>Dive into the World of Technology</h2>
                        <p>Stay informed with the latest updates on Computer Products and Technology Trends. </p>
                    </div>
                    <div class="blog-content">
                        <div class="row">
                            {ProductListdata.map((data, index) => (
                                <div class="col-md-3 col-sm-6" onClick={()=>navigate(`/product/${data["ProductCode"]}`)}>
                                    <div class="single-blog-item">
                                        <div class="single-blog-item-img">
                                            <img src={`/Product/${data["ProductImageFolder"]}/${data["ProductMainImageId"]}`}


                                                alt="blog image" />
                                        </div>
                                        <div class="single-blog-item-txt">
                                            <h4>{data["ProductCode"]}</h4>
                                            <h2><a>{data["ProductName"]}</a></h2>
                                        </div>
                                    </div>
                                </div>
                            ))}


                            {/* <div class="col-md-4 col-sm-6" onclick="window.open('/product/Keyboard.html', '_blank')">
                                <div class="single-blog-item">
                                    <div class="single-blog-item-img">
                                        <img src="../assets/images/Product/Keyboard.jpg"
                                            alt="blog image" />
                                    </div>
                                    <div class="single-blog-item-txt">
                                        <h2><a href="/product/Keyboard.html">KeyBoard Tray BK-063</a></h2>

                                    </div>
                                </div>
                            </div> */}



                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default ProductList;