function Footer() {
    return (
        <>
            <section id="contact" class="subscription">
                <div class="container">
                    <div class="subscribe-title text-center">
                        <h2>
                            Unlock exclusive content and updates
                        </h2>
                        <p>
                            subscribe to our newsletter
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="subscription-input-group">
                                <form action="#">
                                    <input type="email" class="subscription-input-form" placeholder="Enter your email here" />
                                    <button class="appsLand-btn subscribe-btn" onclick="window.location.href='#'">
                                        subscribe
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            {/* <!--footer start--> */}
            <footer id="footer" class="footer">
                <div class="container">
                    <div class="footer-menu">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="navbar-header">
                                    <a class="navbar-brand" href="index.html"><span>Horizon Hub</span></a>
                                </div>
                            </div>
                            <div class="col-sm-9">
                                <div class="footer-social">
                                    {/* <!-- <span><i class="fa fa-globe" onclick="NavigatetoPage()" style="cursor: pointer;">
                    www.medbaymedical.com</i></span> --> */}
                                    <span><i class="fa fa-envelope"> sales@thehorizonhub.com </i></span>
                                    {/* <!-- <span><i class="fa fa-envelope"> sales@medbaymedical.com</i></span> --> */}
                                    <span><i class="fa fa-phone"> +971 585563063</i></span>
                                    {/* <!-- <span><i class="fa fa-phone"> +971 501915660</i></span> --> */}


                                    {/* <!-- <a href="#"><i class="fa fa-facebook"></i></a> */}
                                    <a href="#"><i class="fa fa-twitter"></i></a>
                                    {/* <!-- <a href="#"><i class="fa fa-linkedin"></i></a> --> */}
                                    {/* <!-- <a href="#"><i class="fa fa-google-plus"></i></a> --> */}
                                </div>
                            </div>
                            {/* <!-- <div class="col-sm-9">
                <ul class="footer-menu-item">
                  <li class="scroll"><a href="#services">how it works</a></li>
                  <li class="scroll"><a href="#explore">explore</a></li>
                  <li class="scroll"><a href="#reviews">review</a></li>
                  <li class="scroll"><a href="#blog">blog</a></li>
                  <li class="scroll"><a href="#contact">contact</a></li>
                  <li class=" scroll"><a href="#contact">my account</a></li>
                </ul>/.nav
              </div> --> */}
                        </div>
                    </div>
                    <div class="hm-footer-copyright">
                        <div class="row">
                            <div class="col-sm-5">
                                <p>
                                    &copy;copyright. designed and developed by <a
                                        href="https://defensenetsolutions.com/">defensenetsolutions</a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="scroll-Top">
                    <div className="return-to-top">
                        <i class="fa fa-angle-up" id="scroll-top" data-toggle="tooltip" data-placement="top" title=""
                            data-original-title="Back to Top" aria-hidden="true"></i>
                    </div>

                </div>

            </footer>
        </>
    )
}

export default Footer;