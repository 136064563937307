export const papperProductListdata = [
    {
        "ProductName": "Glove Dispenser",
        "ProductMainImageId": "glovedispenser.jpg",
        "ProductImageFolder": "SS304",
        "Available": true,
        "ProductDescription": "Stainless Steel",
        "ProductCode": "SS304",
        "ProductCharaWithTitle": [
            { "Title": "Dimensions", "Value": "295*130*95 mm" },
            { "Title": "Thickness", "Value": "0.8 mm , 1 mm , 1.2 mm" },
        ],
        "ImageId": [],
        "ProductCharaWithoutTitle": []
    },
    {
        
        "ProductName": "Glove Dispenser",
        "ProductMainImageId": "glovedispenser.jpg",
        "ProductImageFolder": "SS316",
        "Available": true,
        "ProductDescription": "Stainless Steel",
        "ProductCode": "SS316",
        "ProductCharaWithTitle": [
            { "Title": "Dimensions", "Value": "295*130*90 mm" },
            { "Title": "Thickness", "Value": "0.8 mm , 1 mm , 1.2 mm" },
        ],
        "ImageId": [],
        "ProductCharaWithoutTitle": []
    }
    
]