import { useEffect, useRef } from "react";
import { useLocation ,useNavigate} from "react-router-dom"


function Home () {

    const services = useRef(null)
    const blog = useRef(null)
    const home = useRef(null)
    const navigate = useNavigate()

    useEffect(()=>{
        const pathArray = window.location.hash.split('/');
        const lastPathSegment = pathArray[pathArray.length - 1];
        NavigateToSpecificPart(lastPathSegment)
    },[])

    const NavigateToSpecificPart = (PartID) => {
        if (PartID == "home"){home.current.scrollIntoView()} 
        else if (PartID == "blog"){blog.current.scrollIntoView()} 
        else if (PartID == "services"){services.current.scrollIntoView()} 
        else if (PartID == "contact"){window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth', // You can use 'auto' or 'smooth' for smooth scrolling
          })}
      };

      function downloadPDF() {
        // Replace 'path/to/your/file.pdf' with the actual path to your PDF file
        var pdfUrl = '/DownloadablePdf/Horizon Hub Paper Products Catalog.pdf';
    
        var link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Horizon Hub Paper Products Catalog.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      
    return (
        <>
            <section id="home" class="welcome-hero" ref={home}>
                <div class="container">
                    <div class="welcome-hero-txt">
                        <h2>Where paper meets <br /> Perfection </h2>
                        {/* <!-- <p>
						Find Best Place, Restaurant, Hotel, Real State and many more think in just One click 
					</p> --> */}
                    </div>

                </div>

            </section>

            {/* <!--list-topics start --> */}
            <section id="list-topics" class="list-topics">
                <div class="container">
                </div>
                {/* <!--/.container--> */}

            </section>
            {/* <!--list-topics end--> */}

            {/* <!--works start --> */}
            <section id="services" class="works mt-8" ref={services}>
                <div class="container">
                    <div class="section-header ">
                        <h2>Our Expertise</h2>
                        <p> Unveiling Our Proficiency to Elevate Your Experience.</p>
                    </div>
                    <div class="works-content">
                        <div class="row">
                            <div class="col-md-4 col-sm-6" onClick={()=> navigate("/papperandsteelproductlist")}>
                                <div class="single-how-works">
                                    <div class="single-how-works-icon">
                                        <i class="flaticon-location-on-road"></i>
                                    </div>
                                    <h2><a href="#">Papper And Steel Goods</a></h2>
                                    <p>
                                        Elevate your experience with our exquisite selection of premium paper and steel goods. From
                                        luxurious stationery to eco-friendly packaging, explore a world of quality craftsmanship
                                        and sustainable elegance under our Paper and Steel Goods collection.
                                    </p>
                                    <button class="welcome-hero-btn how-work-btn" >
                                        read more
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6" onClick={()=> navigate("/productList")}>
                                <div class="single-how-works">
                                    <div class="single-how-works-icon">
                                        <i class="flaticon-lightbulb-idea"></i>
                                    </div>
                                    <h2><a href='Product.html'>Computer WholeSale</a></h2>
                                    <p>
                                        Unlock limitless possibilities with our Computer Wholesale offerings. Experience
                                        cutting-edge technology and unmatched performance as we provide tailored solutions for
                                        all your computing needs. Empower your business with top-tier hardware and expert
                                        support.
                                    </p>
                                    <a >
                                        <button class="welcome-hero-btn how-work-btn" >
                                            read more
                                        </button>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="single-how-works">
                                    <div class="single-how-works-icon">
                                        <i class="flaticon-networking"></i>
                                    </div>
                                    <h2><a href="#">Sofware Trading</a></h2>
                                    <p>
                                        Navigate the digital landscape seamlessly with our Software Trading expertise. Dive into
                                        a diverse array of innovative solutions tailored for your business. Elevate efficiency,
                                        enhance security, and stay ahead in the dynamic realm of software technology.
                                    </p>
                                    <button class="welcome-hero-btn how-work-btn" onclick="window.location.href='#'">
                                        read more
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!--blog start --> */}
            <section id="blog" class="blog" ref={blog}>
                <div class="container">
                    <div class="section-header">
                        <h2>Immerse Yourself in our Articles</h2>
                        <p>Always upto date with our latest News and Articles </p>
                    </div>
                    <div class="blog-content">
                        <div class="row">
                            <div class="col-md-4 col-sm-6">
                                <div class="single-blog-item">
                                    <div class="single-blog-item-img">
                                        <img src="https://lh3.googleusercontent.com/p/AF1QipN0AjChRjVEK3hEnUuMWj334eDysCUiHD9yNFEo=w1080-h608-p-no-v0"
                                            alt="blog image" />
                                    </div>
                                    <div class="single-blog-item-txt">
                                        <h2><a href="#">Building the VW of PC's</a></h2>
                                        <h4>posted <span>by</span> <a href="#">admin</a> January 2024</h4>
                                        <p>
                                            Before you can build the $300 network computer for the masses, you have to recruit
                                            the engineers to design it.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="single-blog-item">
                                    <div class="single-blog-item-img">
                                        <img src="https://www.ringcentral.com/gb/en/blog/wp-content/uploads/2021/02/speech-recognition-concept-hands-free-communication-machine-translation.jpg.webp"
                                            alt="blog image" />
                                    </div>
                                    <div class="single-blog-item-txt">
                                        <h2><a href="#">Rabbit’s Little Walkie-Talkie Learns Tasks That Stump Siri and Alexa</a>
                                        </h2>
                                        <h4>posted <span>by</span> <a href="#">admin</a> December 2023</h4>
                                        <p>
                                            The startup has developed a virtual assistant that learns whatever digital errands
                                            you teach it. The interface is extra cute: a handheld device you use to issue voice
                                            commands to your bot army.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="single-blog-item">
                                    <div class="single-blog-item-img">
                                        <img src="https://www.zdnet.com/a/img/resize/605df164b50d7127344cce4c41e5e2a36ac951b4/2023/04/05/e0478a88-b3ed-4516-8459-e0b919b4b2bc/artificial-intelligence.jpg?auto=webp&width=1280"
                                            alt="blog image" />
                                    </div>
                                    <div class="single-blog-item-txt">
                                        <h2><a href="#">Soon We Won't Program Computers. We'll Train Them Like Dogs</a></h2>
                                        <h4>posted <span>by</span> <a href="#">admin</a> November 2023</h4>
                                        <p>
                                            Welcome to the new world of artificial intelligence.Soon, we won't program
                                            computers. We'll train them. Like dolphins. Or dogs. Or humans.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section></>
    )
}

export default Home;