import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { HashRouter as Router  } from 'react-router-dom';


import './assets/css/font-awesome.min.css'
import './assets/css/linearicons.css'
import './assets/css/animate.css'
import './assets/css/flaticon.css'
import './assets/css/slick.css'
import './assets/css/slick-theme.css'
import './assets/css/bootstrap.min.css'
import './assets/css/bootsnav.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

