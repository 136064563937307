import Header from "./Header"
import Footer from "./Footer"
import { Route, useMatch,Routes } from "react-router-dom";
import { RouterPaths } from "./RouterPaths";

function DefaultLayout() {
    return (
        <>
            <Header />
            <Routes>
              {RouterPaths &&
                RouterPaths.map((route, key) => (
                  <Route key={key} path={`/${route.path}/*`} element={<route.component/>}/>
                ))}
            </Routes>
            <Footer />
        </>
    )
}
export default DefaultLayout