import DefaultLayout from './Components/DefaultLayout';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';


function App() {

  
  function NavigatetoPage() {
    var webpageURL = 'https://www.medbaymedical.com/';

    // Open the webpage in a new tab
    window.open(webpageURL, '_blank');

  }
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to={'/home'} />} />
        <Route path="/*" element={<DefaultLayout />} />
      </Routes>
    </>
  );
}

export default App;
