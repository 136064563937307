import { useEffect, useState } from "react";
import { ProductListdata } from "../assets/Productlist";
import { papperProductListdata } from "../assets/Papper_Productlist";


function Product() {
    const [ProductData, setProductData] = useState({})
    const [mainDisplayImageUrl,setMainDisplayImageUrl] = useState("")
    useEffect(() => {
        const pathArray = window.location.hash.split('/');
        const lastPathSegment = pathArray[pathArray.length - 1];
        findItemByProductCode(lastPathSegment)
    }, [])

    const findItemByProductCode = (productCode) => {
        console.log(productCode)
        let data = ProductListdata.find(item => item.ProductCode === productCode);
        if (data === undefined) {data = papperProductListdata.find(item => item.ProductCode === productCode);} 
        setProductData(data)
        setMainDisplayImageUrl(`${data["ProductImageFolder"]}/${data["ProductMainImageId"]}`)
    };

    const ChangeImage = (url) => {
        setMainDisplayImageUrl(url)
    }
    return (
        <section class="product-header">
            <div class="">
                <div class="section-header">
                    <h2>{ProductData && ProductData["ProductName"]}</h2>
                    <p>{ProductData && ProductData["ProductDescription"]} </p>
                </div>
                <div class="blog-content">
                    <div class="row">
                        <div class="col-md-6 col-sm-12 product-main-image" >
                            <div class="single-blog-item fixed-height-and-width">
                                <div class="single-blog-item-img">
                                    <img src={mainDisplayImageUrl != "" && 
                                    `/Product/${mainDisplayImageUrl}`} 
                                    alt="Main Image" id="ChangeAbleImage" 
                                    style={{ height: '100%', width: 'auto' }}
                                    />
                                </div>

                            </div>

                        </div>

                        <div class="col-md-6 col-sm-12 mr-4">
                            <div class="single-blog-item-txt left-align-text">
                                <h2 ><a href="#"></a>{ProductData && ProductData["ProductCode"]}</h2>
                                <h2 class={ProductData && ProductData["Available"] ? "available" : "unavailable"}><a href="#">{ProductData && ProductData["Available"] ? "Currently Available" : "Currently Unavailable"}</a></h2>
                                {/* <!-- <h2><a href="#">Provides Easy Access to the PC</a></h2>
                            <h2><a href="#">Protects against dust or damage</a></h2> --> */}
                                {ProductData && ProductData["ProductCharaWithTitle"] && ProductData["ProductCharaWithTitle"].map((data, index) => (
                                    <h4 key={index}>{data["Title"]}<span></span> <a href="#">{data["Value"]}</a> </h4>
                                ))}
                                {ProductData && ProductData["ProductCharaWithoutTitle"] && ProductData["ProductCharaWithoutTitle"].map((data, index) => (
                                    <h4 key={index}>{data}</h4>
                                ))}

                                {/* <!-- <p class="left-align-text">
									Width Adjustableelcome to the new world of artificial intelligence.Soon, we won't program
									computers. We'll train them. Like dolphins. Or dogs. Or humans.
								</p> --> */}
                            </div>
                            <div class="row product-image-container">
                                <div class="col-md-2 col-sm-4"  onClick={()=>ChangeImage(`${ProductData["ProductImageFolder"]}/${ProductData["ProductMainImageId"]}`)}>
                                        <div class="single-blog-item fixed-height-and-width">
                                            <div class="single-blog-item-img">
                                            <img src={ProductData && ProductData["ProductImageFolder"] && ProductData["ProductMainImageId"] && `/Product/${ProductData["ProductImageFolder"]}/${ProductData["ProductMainImageId"]}`} alt="Main Image" id="ChangeAbleImage" />
                                            </div>

                                        </div>
                                    </div>
                                {ProductData && ProductData["ImageId"] && ProductData["ImageId"].map((data, index) => (
                                    <div class="col-md-2 col-sm-4" key={index} onClick={()=>ChangeImage(`${ProductData["ProductImageFolder"]}/${data}`)}>
                                        <div class="single-blog-item fixed-height-and-width">
                                            <div class="single-blog-item-img">
                                                <img src={
                                                    ProductData &&
                                                    ProductData["ProductImageFolder"] &&
                                                    `/Product/${ProductData["ProductImageFolder"]}/${data}`}
                                                    alt={`Image${index}`} id="ChangeAbleImage" />
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section >
    )
}

export default Product;