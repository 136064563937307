import Home from "./Home"
import Product from "./Product"
import ProductList from "./ProductList"
import PapperAndSteelProductList from "./PapperAndSteelProductList"

export const RouterPaths = [
    {
        path:'home',
        component: Home
    },{
        path:'services',
        component: Home
    },{
        path:'blog',
        component: Home
    },{
        path:'contact',
        component: Home
    },{
        path:'product',
        component: Product
    },{
        path:'productlist',
        component: ProductList
    },{
        path:'papperandsteelproductlist',
        component: PapperAndSteelProductList
    },
    
]
