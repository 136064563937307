import { useEffect } from "react";
import { papperProductListdata } from "../assets/Papper_Productlist";
import { useNavigate } from "react-router-dom";
function PapperAndSteelProductList() {
    const navigate = useNavigate()
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' or 'smooth' for smooth scrolling
          })
    },[])

    function downloadPDF() {
        // Replace 'path/to/your/file.pdf' with the actual path to your PDF file
        var pdfUrl = '/DownloadablePdf/Horizon Hub Paper Products Catalog.pdf';
    
        var link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Horizon Hub Paper Products Catalog.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    return (
        <>
            <section class="paper-page-header">
                <div class="container">
                    <div class="welcome-hero-txt">
                        <h2>WHERE PAPER and steel MEETS<br /> PERFECTION! </h2>
                    </div>

                </div>
            </section>
            <section id="blog" class="blog">
                <div class="container">
                    <div class="section-header">
                        <h2>Dive into the World of Paper and Steel</h2>
                        <p>Stay informed with the latest updates on Paper and Steel Products. </p>
                    </div>
                    <div class="blog-content">
                        <div class="row">
                            <div class="col-md-3 col-sm-6" onClick={downloadPDF}>
                                    <div class="single-blog-item">
                                        <div class="single-blog-item-img">
                                            <img src="/Product/paper/PapperProduct.png"


                                                alt="blog image" />
                                        </div>
                                        <div class="single-blog-item-txt">
                                            <h4>Catalog</h4>
                                            <h2><a>Paper Products </a></h2>
                                        </div>
                                    </div>
                                </div>
                            {papperProductListdata.map((data, index) => (
                                <div class="col-md-3 col-sm-6" onClick={()=>navigate(`/product/${data["ProductCode"]}`)}>
                                    <div class="single-blog-item">
                                        <div class="single-blog-item-img">
                                            <img src={`/Product/${data["ProductImageFolder"]}/${data["ProductMainImageId"]}`}


                                                alt="blog image" />
                                        </div>
                                        <div class="single-blog-item-txt">
                                            <h4>{data["ProductCode"]}</h4>
                                            <h2><a>{data["ProductName"]}</a></h2>
                                        </div>
                                    </div>
                                </div>
                            ))}


                            {/* <div class="col-md-4 col-sm-6" onclick="window.open('/product/Keyboard.html', '_blank')">
                                <div class="single-blog-item">
                                    <div class="single-blog-item-img">
                                        <img src="../assets/images/Product/Keyboard.jpg"
                                            alt="blog image" />
                                    </div>
                                    <div class="single-blog-item-txt">
                                        <h2><a href="/product/Keyboard.html">KeyBoard Tray BK-063</a></h2>

                                    </div>
                                </div>
                            </div> */}



                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default PapperAndSteelProductList;