import { useLocation ,useNavigate} from "react-router-dom"

function Header() {

    const navigate = useNavigate()
    const ClickedAnOption = (pageName) => {
        navigate(pageName)
    }

    return (
        <>
            <header id="header-top" class="header-top">
                <ul>
                    <li>
                        <div class="header-top-left">
                            <ul>
                                <li class="header-top-contact">
                                    <i class="fa fa-envelope"> sales@thehorizonhub.com </i>
                                </li>
                                <li class="header-top-contact">
                                    <i class="fa fa-phone"> +971 585563063</i>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="head-responsive-right pull-right">
                        <div class="header-top-right">
                            <ul>
                            </ul>
                        </div>
                    </li>
                </ul>

            </header>
            <section class="top-area">
                <div class="header-area">
                    {/* Start Navigation */}
                    <nav class="navbar navbar-default bootsnav  navbar-sticky navbar-scrollspy" data-minus-value-desktop="70"
                        data-minus-value-mobile="55" data-speed="1000">

                        <div class="container">

                            {/* Start Header Navigation */}
                            <div class="navbar-header">

                                <a class="navbar-brand" href="index.html">Horizon Hub</a>

                            </div>
                            {/* <!-- End Header Navigation --> */}

                            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
                            <div class="collapse navbar-collapse menu-ui-design" id="navbar-menu">
                                <ul class="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
                                    <li class=" scroll active"><a onClick={()=>ClickedAnOption("home")}>home</a></li>
                                    {/* <!-- <li class="scroll"><a href="#explore">About</a></li> --> */}
                                    <li class="scroll"><a onClick={()=>ClickedAnOption("services")}>Services</a></li>

                                    {/* <!-- <li class="scroll"><a href="#reviews">review</a></li> --> */}
                                    <li class="scroll"><a onClick={()=>ClickedAnOption("blog")}>Blog</a></li>
                                    <li class="scroll"><a onClick={()=>ClickedAnOption("contact")}>Contact</a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* <!-- End Navigation --> */}
                </div>{/* <!--/.header-area--> */}
                <div class="clearfix"></div>

            </section>
        </>
    )
}

export default Header;