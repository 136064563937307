export const ProductListdata = [
    {
        "ProductName": "CPU Holder",
        "ProductMainImageId": "Cpu.jpg",
        "ProductImageFolder": "cpu",
        "Available": true,
        "ProductDescription": "Provides easy access to the pc and Protects againts dust and damage ",
        "ProductCode": "XC-52",
        "ProductCharaWithTitle": [
            { "Title": "Color/finish", "Value": "Black Epoxy Coated " },
            { "Title": "Weight Capacity", "Value": "10Kg/22lbs " },
            { "Title": "Height Range", "Value": '300-533mm/11.8"-21"' },
            { "Title": "Depth Range", "Value": '88-203mm/3.5"-8"' },
        ],
        "ImageId": ["cpu1.jpg", "cpu2.jpg", "cpu3.jpg", "cpu4.jpg", "cpu5.jpg", "cpu6.jpg"],
        "ProductCharaWithoutTitle": ["Width Adjustable", "Depth Adjustable", "360 Degree"]
    },{
        
        "ProductName": "KeyBoard Tray",
        "ProductMainImageId": "Keyboard.jpg",
        "ProductImageFolder": "keyboard",
        "Available": false,
        "ProductDescription": "Provides easy access to the pc and Protects againts dust and damage ",
        "ProductCode": "BK-063",
        "ProductCharaWithTitle": [
            { "Title": "Color/finish", "Value": "Black & Grey Formica" },
            { "Title": "Width Adjustment", "Value": "300 mm" },
            { "Title": "Height Adjustment", "Value": '120 mm' },
            { "Title": "Length", "Value": '640 mm' },
        ],
        "ImageId": ["Key1.jpg","Key2.jpg","Key3.jpg","Key4.jpg","Key5.jpg","Key6.jpg"],
        "ProductCharaWithoutTitle": ["Screw Installation","Under the desk Installation"]
    
    },
    {
        
        "ProductName": "Hafele CPU Holder",
        "ProductMainImageId": "CPUHafele1.jpg",
        "ProductImageFolder": "cpuHafele",
        "Available": true,
        "ProductDescription": "Provides easy access to the pc and Protects againts dust and damage ",
        "ProductCode": "CA2445",
        "ProductCharaWithTitle": [
            { "Title": "Color/finish", "Value": "Black, Epoxy Coated" },
            { "Title": "Width Adjustment", "Value": "130 -235 mm" },
            { "Title": "Height Adjustment", "Value": '320 - 500 mm' },
        ],
        "ImageId": ["CPUHafele2.jpg","CPUHafele3.jpg","CPUHafele4.jpg","CPUHafele5.jpg","CPUHafele6.jpg","CPUHafele7.jpg","CPUHafele8.jpg"],
        "ProductCharaWithoutTitle": ["Screw Installation","Under the desk Installation"]
    
    },
    {
        
        "ProductName": "Under Desk Keyboard Tray",
        "ProductMainImageId": "KeyBoard1.jpg",
        "ProductImageFolder": "KeyboardUnderTable",
        "Available": true,
        "ProductDescription": "Airlift Negative tilt & swivel under-desk keyboard tray with gel wristand precise mouse pad",
        "ProductCode": "KBT-04",
        "ProductCharaWithTitle": [
            { "Title": "Product Category", "Value": "UNder desk Keyboard Tray" },
            { "Title": "Rank", "Value": "Standard" },
            { "Title": "Color", "Value": 'Matte Black & Black' },
            { "Title": "Dimension", "Value": '670X1130x145~455mm' },
            { "Title": "KeyBoard Tray Size", "Value": '670X255mm' },
            { "Title": "Weight Capacity", "Value": '2 Kg' },
        ],
        "ImageId": ["KeyBoard2.jpg","KeyBoard3.jpg","KeyBoard4.jpg","KeyBoard5.jpg","KeyBoard6.jpg","KeyBoard7.jpg","KeyBoard8.jpg","KeyBoard9.jpg"],
        "ProductCharaWithoutTitle": ["Comfortable Design", "Height Adjustment" , "Rotation 360" , "Tiltable Design for Reducing Strain on Wrist" , "Nicely Padded Edge For Typing Comfort","Cable Slot"]
    
    },
    {
        
        "ProductName": "Cutout Keyboard Tray",
        "ProductMainImageId": "KeyBoard1.png",
        "ProductImageFolder": "CutoutKeyboardTray",
        "Available": true,
        "ProductDescription": "Under Desk slide rail guided keyboard tray with adjustable mousepad.Designed to exactly conform the way of placing keyboard",
        "ProductCode": "HH-01",
        "ProductCharaWithTitle": [
            { "Title": "Product Category", "Value": "UNder desk Keyboard Tray" },
            { "Title": "Rank", "Value": "Standard" },
            { "Title": "Material", "Value": 'Steel, Abs Plastic' },
            { "Title": "Color", "Value": 'Black,White,Grey' },
            { "Title": "Dimension", "Value": '582*270*202*200 mm' },
            { "Title": "KeyBoard Tray Size", "Value": '554*270mm' },
            { "Title": "Weight Capacity", "Value": '2 Kg' },
        ],
        "ImageId": ["KeyBoard3.png","KeyBoard4.png","KeyBoard5.png","KeyBoard6.png","KeyBoard7.png","KeyBoard2.png"],
        "ProductCharaWithoutTitle": ["Keyboard holder platform with ball bearing runners for extraction." , "includes auxiliary tray for mouse and mouse pad." , "made from Abs Plastic & Steel"]
    
    }
    
]